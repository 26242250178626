// src/@chakra-ui/gatsby-plugin/theme.js
import {
  extendTheme
} from '@chakra-ui/react';

const theme = {
  fonts: {
    body: `"InterVariable", sans-serif`,
    heading: `"InterVariable", sans-serif`,
    mono: "consolas, monospace",
  },
  colors: {
    brand: {
      50: '#dff4ff',
      100: '#bcdff3',
      200: '#96cde7',
      300: '#6fbbdd',
      400: '#4a9fd2',
      500: '#317cb8',
      600: '#225990',
      700: '#153968',
      800: '#051b41',
      900: '#00071b',
    },
    secondary: {
      50: '#f3f6ff',
      100: '#cbd4e8',
      200: '#acc3d3',
      300: '#8ca9c0',
      400: '#6c8ead',
      500: '#527293',
      600: '#3f5773',
      700: '#2c4153',
      800: '#182934',
      900: '#021118',
    }
  },
}


export default extendTheme(theme)